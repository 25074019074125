import { get, post, destroy, BaseUrl, put } from './api';

const controller = "WorkflowDefenition";

export const getWorkflowDefinitions = async () => {
    return await get(controller, null, BaseUrl.WorkFlow);
}

export const getWorkflowDefinition = async (workflowId) => {
    return await get(`${controller}/${workflowId}`, null, BaseUrl.WorkFlow);
}

export const getWorkflowDefinitionValues = async (workflowId) => {
    return await get(`${controller}/${workflowId}/values`, null, BaseUrl.WorkFlow);
}

export const createDefaultWorkflowDefinition = async (name) => {
    return await post(controller + '/default', { name }, null, BaseUrl.WorkFlow);
}

export const updateWorkflowDefinition = async (workflowDefinition) => {
    return await put(controller, workflowDefinition, null, BaseUrl.WorkFlow);
}

export const activateWorkflowDefinition = async (workflowId) => {
    return await put(`${controller}/${workflowId}/activate`, {}, null, BaseUrl.WorkFlow);
}

export const deactivateWorkflowDefinition = async (workflowId) => {
    return await put(`${controller}/${workflowId}/deactivate`, {}, null, BaseUrl.WorkFlow);
}

export const destroyWorkflowDefinition = async (workflowId) => {
    return await destroy(`${controller}/${workflowId}`, null, BaseUrl.WorkFlow);
}

export const testWorkflowStep = async (workflowId, workflowStepId, testValues) => {
    return await post(`${controller}/${workflowId}/test/${workflowStepId}`, { testValues: testValues }, null, BaseUrl.WorkFlow);
}