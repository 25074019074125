import { Disclosure } from "@headlessui/react";
import { BanIcon, XIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useRef, useState } from "react"
import { ClickOutside } from "../../../../../components/ClickOutside";
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from "../../../../../contexts";
import { usePreviousSteps } from "../../../../../hooks/usePreviousSteps";
import { workflowAppType } from "../../../../../utils/constants";
import { convertValuesToArray, placeHolderExists } from "../../utils";
import WorkflowEventAppIcon from "../../workflowAppIcon";
import { StepValueOptions } from "../stepValueOptions";


const StepValuesMenu = ({ fieldRef, stepsAvailable, onFieldChange }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const stepContext = useContext(WorkflowStepContext);
    const handleRefClick = () => {
        setMenuOpen(true);
    }

    useEffect(() => {
        fieldRef.current.addEventListener("click", handleRefClick)
        return () => {
            fieldRef?.current?.removeventListener("click", handleRefClick)
        }
    }, []);

    const handleAddElement = (option) => {
        onFieldChange(option);
    }

    return (
        <div className="w-96 sm:w-full relative">
            <Disclosure>
                {({ open }) => (
                    <>
                        {menuOpen && (
                            <ClickOutside onOutsideClick={() => {
                                if (menuOpen) {
                                    setMenuOpen(false);
                                }
                            }}
                                ignoreRef={fieldRef}
                            >
                                <Disclosure.Panel
                                    static className="w-full origin-bottom-left top-2 absolute -left-4 sm:left-0 z-10 px-4 pt-4 pb-4 text-sm text-gray-500 bg-white shadow rounded-lg mb-12 max-h-80 overflow-y-auto"

                                >

                                    <div className="text-gray-800">
                                        {
                                            stepsAvailable.map((sa, i) => {
                                                return (<StepValueOptions key={sa.id} stepValues={stepContext.previousStepsValues[i]} step={sa} onAdd={option => handleAddElement(option)} isCollapsedDefault={i === 0} />)
                                            })
                                        }
                                    </div>
                                </Disclosure.Panel>
                            </ClickOutside>
                        )}
                    </>
                )}
            </Disclosure>
        </div>
    );
}

const ConditionsMenu = ({ fieldRef, onConditionChange }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleRefClick = () => {
        setMenuOpen(true);
    }

    const conditions = [
        {
            key: "text-contains",
            name: "Text Contains"
        },
        {
            key: "text-matches",
            name: "Text Matches",
        },
        {
            key: "text-not-contains",
            name: "Text Not Contains"
        },
        {
            key: "bool-is-true",
            name: "Boolean is True"
        },
        {
            key: "bool-is-false",
            name: "Boolean Is False"
        }
    ]


    useEffect(() => {
        fieldRef.current.addEventListener("click", handleRefClick)
        return () => {
            fieldRef?.current?.removeventListener("click", handleRefClick)
        }
    }, []);

    const handleSelectCondition = (condition) => {
        onConditionChange(condition);
    }

    return (
        <div className="w-96 sm:w-full relative">
            <Disclosure>
                {({ open }) => (
                    <>
                        {menuOpen && (
                            <ClickOutside onOutsideClick={() => {
                                if (menuOpen) {
                                    setMenuOpen(false);
                                }
                            }}
                                ignoreRef={fieldRef}
                            >
                                <Disclosure.Panel
                                    static className="w-full origin-bottom-left top-2 absolute -left-32 sm:left-0 z-10 px-4 pt-4 pb-4 text-sm text-gray-500 bg-white shadow rounded-lg mb-12 max-h-80 overflow-y-auto"

                                >

                                    <div className="text-gray-800 space-y-2">
                                        {
                                            conditions.map((c, i) => {
                                                return (
                                                    (<div key={c.key} className="flex items-center space-x-2 cursor-pointer rounded-lg border-gray-200 border p-1 hover:shadow"
                                                        onClick={e => {
                                                            handleSelectCondition(c);
                                                        }}
                                                    >
                                                        <div className="font-semibold text-md"> <span>{c.name}</span></div>
                                                    </div>)
                                                )
                                            })
                                        }
                                    </div>
                                </Disclosure.Panel>
                            </ClickOutside>
                        )}
                    </>
                )}
            </Disclosure>
        </div>
    );
}

const FilterCondtion = ({ filterCondition, onFilterConditionChange }) => {

    const fieldRef = useRef(null);
    const conditionRef = useRef(null);
    const [buildCondition, setBuildCondition] = useState(filterCondition || {})
    const { classNames } = useClassNames();
    const stepContext = useContext(WorkflowStepContext);

    const handleConditionValueChange = (value) => {
        setBuildCondition(prev => {
            return { ...prev, value: value };
        })
    }

    const handleFieldChange = (field) => {
        setBuildCondition(prev => {
            return { ...prev, field };
        })
    }

    const handleConditionChange = (condition) => {
        setBuildCondition(prev => {
            return { ...prev, condition };
        })
    }

    useEffect(() => {
        onFilterConditionChange(buildCondition)
    }, [buildCondition]);


    return (
        <div className="grid grid-cols-6 space-x-2">
            <div className="rounded col-span-2 bg-gray-100" ref={fieldRef}>
                <div className="px-2 py-2 cursor-pointer">
                    {
                        buildCondition.field ? (
                            <span
                                className={classNames("space-x-2 px-2 cursor-pointer bg-white rounded border-gray-200 border hover:shadow")}
                            >
                                <WorkflowEventAppIcon iconKey={buildCondition.field.icon} className={classNames("h-4 w-5  inline-block shadow rounded p-0.5",
                                    placeHolderExists(stepContext.previousStepsValues, buildCondition.field) ? "" : "opacity-50")} />
                                <span className={classNames("font-semibold text-md", placeHolderExists(stepContext.previousStepsValues, buildCondition.field) ? "" : "opacity-30")}> <span>{placeHolderExists(stepContext.previousStepsValues, buildCondition.field) ? "" : "(No Data)"}</span> <span>{buildCondition.field.stepIdx + 1}. {buildCondition.field.name}</span></span>
                            </span>
                        ) : (
                            <span className="text-gray-500">
                                Chose property
                            </span>)
                    }
                </div>
                <StepValuesMenu fieldRef={fieldRef} stepsAvailable={stepContext.previousSteps} onFieldChange={e => handleFieldChange(e)} />
            </div>

            <div className="rounded col-span-2 bg-gray-100 cursor-pointer" ref={conditionRef}>
                <div className="px-2 py-2">
                    {
                        buildCondition.condition ? (
                            <span className="bg-white rounded border border-gray-300 text-md font-semibold px-2 py-.5">
                                {buildCondition.condition.name}
                            </span>
                        ) : (
                            <span className="text-gray-500">
                                Chose condition
                            </span>
                        )
                    }
                </div>
                <ConditionsMenu fieldRef={conditionRef} onConditionChange={e => handleConditionChange(e)} />
            </div>
            <div className=" rounded col-span-2 bg-gray-100">
                <FilterConditionValue buildCondition={buildCondition} onChange={handleConditionValueChange} />
            </div>
        </div >
    )
}

const FilterConditionValue = ({ buildCondition, onChange }) => {
    const inputClasses = "rounded px-2 py-2 border-none w-full bg-transparent";

    switch (buildCondition.condition?.key) {
        case "bool-is-true": return (
            <input name="conditionValue" type="text" placeholder="True"
                className={inputClasses}
                value={""}
                disabled={true}
            />
        )
        case "bool-is-false": return (
            <input name="conditionValue" type="text" placeholder="False"
                className={inputClasses}
                value={""}
                disabled={true}
            />
        )
        default:
            return (<input name="conditionValue" type="text" placeholder="Condition value"
                className={inputClasses}
                value={buildCondition?.value || ""}
                onChange={e => {
                    onChange(e.target.value);
                }}
            />)
    }
}


const FilterConditionsRulesSetup = ({ workflowStepId, filterGroups, onFilterGroupsChange, branchId }) => {
    const workflowContext = useContext(WorkflowContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const { workFlowValues } = useContext(WorkflowValuesContext);
    const { classNames } = useClassNames();
    const { filterPreviousSteps } = usePreviousSteps(workflowContext.workFlow.workflowSteps, workflowContext.workFlow.workflowBranches);

    const handleFilterConditionChange = (groupIdx, i, condition) => {
        filterGroups[groupIdx].groupFilters[i] = condition;
        const updated = [...filterGroups]
        onFilterGroupsChange(updated);
    }

    const handleAddFilterToGroup = (groupIdx) => {

        const updatedGroup = [...filterGroups[groupIdx].groupFilters, {}]
        filterGroups[groupIdx].groupFilters = updatedGroup;
        const updated = [...filterGroups];
        onFilterGroupsChange(updated);
    }

    const handleAddFilterGroup = () => {
        onFilterGroupsChange([...filterGroups, { groupFilters: [{}] }])
    }

    const handleRemoveFilter = (groupIdx, i) => {
        if (filterGroups.length === 1 && filterGroups[groupIdx].groupFilters.length > 1) {
            filterGroups[groupIdx].groupFilters.splice(i, 1)
            const updatedGroup = [...filterGroups[groupIdx].groupFilters]
            if (updatedGroup.length > 0) {
                filterGroups[groupIdx].groupFilters = updatedGroup;
                onFilterGroupsChange([...filterGroups]);
            }
        } else if (filterGroups.length > 1) {
            filterGroups[groupIdx].groupFilters.splice(i, 1);
            const updatedGroup = [...filterGroups[groupIdx].groupFilters]
            if (updatedGroup.length > 0) {
                filterGroups[groupIdx].groupFilters = updatedGroup;
                onFilterGroupsChange([...filterGroups])
            } else {
                filterGroups.splice(groupIdx, 1);
                onFilterGroupsChange([...filterGroups])
            }
        }

    }

    return (
        <div>
            <span>Continue if</span>
            {
                filterGroups.map((fg, gIdx) => {
                    return (<div key={"gr-" + gIdx}>
                        {
                            gIdx > 0 && (
                                <div className="mt-4 py-4 border-t border-solid border-gray-400">
                                    Or if
                                </div>
                            )
                        }
                        <div>
                            {
                                fg.groupFilters && fg.groupFilters.map((gf, i) => {
                                    return (
                                        <div key={"fi-" + gIdx + i} className="flex items-center mt-2 space-x-2">
                                            <div className="flex-grow">
                                                <FilterCondtion filterCondition={gf} onFilterConditionChange={e => handleFilterConditionChange(gIdx, i, e)} />
                                            </div>
                                            <div className={classNames("flex-none text-lg h-full w-8 text-gray-500 p-1 border border-solid border-gray-400 rounded-lg",
                                                gIdx === 0 && i === 0 ? "opacity-0" : "cursor-pointer hover:shadow hover:text-red-600"
                                            )} title="Remove"
                                                onClick={e => {
                                                    if (gIdx === 0 && i === 0) {
                                                        return;
                                                    }
                                                    handleRemoveFilter(gIdx, i)
                                                }}
                                            >
                                                <XIcon />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="mt-2 flex space-x-2">
                                <div className="rounded bg-gray-200 px-4 cursor-pointer hover:shadow text-gray-600 font-semibold" onClick={e => {
                                    handleAddFilterToGroup(gIdx);
                                }}>
                                    + And
                                </div>
                                {
                                    gIdx === filterGroups.length - 1 && (
                                        <div className="rounded bg-gray-200 px-4 cursor-pointer hover:shadow text-gray-600 font-semibold"
                                            onClick={e => {
                                                handleAddFilterGroup();
                                            }}
                                        >
                                            + Or
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                    )
                })
            }
        </div>)

}

export const BranchConditionsSetupAction = ({ branchFilterGroups, onFilterGroupsChange, branchId }) => {
    const [filterGroups, setFilterGroups] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    const handleFilterGroupsChange = (filterGroups) => {
        setFilterGroups(filterGroups);
    }

    useEffect(() => {
        // setFilterGroups([{ groupFilters: [{}] }])
        if (branchFilterGroups.length === 0) {
            setFilterGroups([{ groupFilters: [{}] }])
        } else if (branchFilterGroups.length > 0 && branchFilterGroups[0].groupFilters.length === 0) {
            filterGroups[0].groupFilters = [{}];
            setFilterGroups(prev => {
                return [...filterGroups]
            })
        } else {
            setFilterGroups(prev => {
                return [...branchFilterGroups];
            })
        }

        setIsLoaded(true);

    }, []);

    useEffect(() => {
        if (isLoaded) {
            onFilterGroupsChange(filterGroups);
        }
    }, [filterGroups])

    return (
        <FilterConditionsRulesSetup branchId={branchId} workflowStepId={null} filterGroups={filterGroups} onFilterGroupsChange={filterGroups => handleFilterGroupsChange(filterGroups)} />
    )

}

export const FilterConditionsSetupActionStep = ({ setupActionStep, workflowStepValues, workflowStep }) => {

    const workflowContext = useContext(WorkflowContext);
    const [filterGroups, setFilterGroups] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!workflowStep.filterGroups || workflowStep.filterGroups?.length === 0) {
            setFilterGroups([{ groupFilters: [{}] }])
        } else if (workflowStep.filterGroups?.length > 0 && workflowStep.filterGroups[0].groupFilters.length === 0) {
            filterGroups[0].groupFilters = [{}];
            setFilterGroups(prev => {
                return [...filterGroups]
            })
        } else {
            setFilterGroups(prev => {
                return [...workflowStep.filterGroups];
            })
        }

        setIsLoaded(true);

    }, []);

    useEffect(() => {
        if (isLoaded) {
            workflowContext.setFilterGroups(workflowStep.id, filterGroups);
        }
    }, [filterGroups]);

    const handleFilterGroupsChange = (filterGroups) => {
        setFilterGroups(filterGroups);
    }

    return (
        <FilterConditionsRulesSetup workflowStepId={workflowStep.id} filterGroups={filterGroups} onFilterGroupsChange={filterGroups => handleFilterGroupsChange(filterGroups)} />
    )
}