import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from "react";
import { getDialogDefinition } from "../../../../api/dialogDefinition";
import { BranchContext, WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from "../../../../contexts";
import { usePreviousSteps } from "../../../../hooks/usePreviousSteps";
import { appEventIconName, branchType, workflowAppType } from "../../../../utils/constants";
import WorkflowEventAppIcon from "../workflowAppIcon";
import NextStepArrow from "../Components/nextStepArrow";
import { BranchConditionsSetupAction } from "../Components/SetupActionSteps/filterConditionsSetupActionStep";
import { stepValidator } from "../stepValidator";
import { convertValuesToArray } from "../utils";

export const BranchTriggerConfigStep = ({ branch, activeStep, setActiveStep }) => {

    const [workflowStepid, setWorkflowStepid] = useState();
    const { workFlow: workflow, mapDialogElementsToWorkflowElement,...workflowContext } = useContext(WorkflowContext);
    const branchContext = useContext(BranchContext);
    const { workFlowValues } = useContext(WorkflowValuesContext);
    const { classNames } = useClassNames();
    const [previousSteps, setPreviousSteps] = useState([]);
    const { filterPreviousSteps } = usePreviousSteps(workflow.workflowSteps, workflow.workflowBranches);
    const [previousStepsValues, setPreviousStepsValues] = useState([]);

    const handleFilterGroupsChange = (filterGroups) => {
        branch.triggerFilterGroups = filterGroups;
        workflowContext.updateBranch(branch)
    }

    const handleAddNewStepToBranch = () => {
        workflowContext.addStep(null, branch.id)
    }
    const loadStepValues = async (stepValues) => {
        var valuePromises = stepValues.values.map((v, i) => {
            if (v.key == "smartformId") {
                return new Promise(async (resolve, reject) => {
                    const elements = await getDialogDefinition(v.value.value);
                    const converted = elements.map(e => {
                        return mapDialogElementsToWorkflowElement(e)
                    })
                    resolve(converted)
                })
            }

            return Promise.resolve([v]);
        });
        if (stepValues.appEvent.type === workflowAppType.restApi) {
            var apiResponseValues = stepValues.values.find(x => x.key === "api-response")?.value;
            if (apiResponseValues) {
                valuePromises = Object.keys(apiResponseValues).map(k => {
                    return Promise.resolve({ key: k, value: { value: apiResponseValues[k], name: k } });
                });
            }
        }

        const values = (await Promise.all(valuePromises)).flat(1);
        return values.map(v => ({
            name: v.value.name,
            id: v.key,
            stepIdx: stepValues.stepIdx,
            stepName: stepValues.name,
            stepId: stepValues.id,
            icon: stepValues.appEvent.appIcon
        }));
    }

    const loadStepsValues = async () => {
        const promises = previousSteps.map(stepValues => loadStepValues(stepValues));
        const values = await Promise.all(promises);
        setPreviousStepsValues(values);
    }

    useEffect(() => {
        const parentBranchSteps = workflow.workflowSteps.filter(s => s.branchId === branch.parentBranchId);
        const branchStepFrom = parentBranchSteps.find(x => x.appEvent.type === workflowAppType.branch || x.appEvent.type === workflowAppType.distribution);
        setWorkflowStepid(branchStepFrom.id);
        loadPrevSteps(branchStepFrom.id)
    }, []);

    const loadPrevSteps = (stepId) => {
        const steps = filterPreviousSteps(stepId).map(({ appEvent, id, name }, i) => {
            return { appEvent, id, name, stepIdx: i, values: convertValuesToArray(workFlowValues[id] || {}) }
        });
        setPreviousSteps(steps);
    }

    useEffect(() => {
        // the config "step" of each branch will enter here with brach id as that step doesnt hold any stepId
        // we therefore work out the parent step of that branch as the step for which we are retreiveing previous values from
        if (!workflowStepid) {
            return;
        }

        loadPrevSteps(workflowStepid);

    }, [workflow]);

    useEffect(() => {
        loadStepsValues();
    }, [previousSteps]);

    // useEffect(() => {
    //     validateStep();
    // }, [previousStepsValues]);

    const getIconForType = (bt) => {
        if (!bt) return appEventIconName.branchIcon;

        switch (bt) {
            case branchType.branch: return appEventIconName.branchIcon;
            case branchType.distribution: return appEventIconName.distributionIcon;
            default: return appEventIconName.branchIcon;
        }
    }

    return (
        <WorkflowStepContext.Provider value={{
            previousSteps: previousSteps,
            previousStepsValues: previousStepsValues,
        }}>
            <div className="max-w-4xl">
                <div className={classNames("w-fullrounded-lg border shadow-lg divide-y divide-gray-200",
                    activeStep ? "" : "cursor-pointer opacity-70 hover:opacity-100"
                )}
                    onClick={e => {
                        if (!activeStep) {
                            setActiveStep();
                        }
                    }}
                >

                    <div className={classNames("flex justify-between space-x-4 items-center px-4 py-5 sm:px-6",
                        "bg-white",
                        activeStep ? "rounded-t-lg" : "rounded-lg")}>
                        <div className={classNames("flex items-center space-x-2 sm:space-x-4 w-full sm:w-3/4")}>
                            <>
                                <WorkflowEventAppIcon iconKey={getIconForType(branchContext.branch.branchType)} className="flex-none rounded-lg shadow h-14 w-14 p-2" />
                                <div className="w-full">
                                    <div>
                                        <span className="text-gray-600">
                                            {
                                                (!branchContext || (branchContext && branchContext.branch.branchType === branchType.branch)) && ("Branch")
                                            }
                                            {
                                                branchContext && branchContext.branch.branchType === branchType.distribution && ("Distribution")
                                            }
                                        </span>
                                    </div>
                                    <div><h3 className="py-1 font-semibold border border-white" >
                                        {
                                            (!branchContext || (branchContext && branchContext.branch.branchType === branchType.branch)) && ("1. Branch Rules")
                                        }
                                        {
                                            branchContext && branchContext.branch.branchType === branchType.distribution && ("1. Distribution Rules")
                                        }
                                        
                                    </h3></div>
                                </div>
                            </>
                        </div>
                        <div>

                        </div>
                    </div>
                    {
                        activeStep && (
                            <div className="bg-white divide-y divide-gray-200 py-4 px-6">
                                <BranchConditionsSetupAction branchId={branch.id} branchFilterGroups={branch.triggerFilterGroups} onFilterGroupsChange={filterGroups => handleFilterGroupsChange(filterGroups)} />
                            </div>
                        )
                    }

                </div >
                <NextStepArrow isLastStep={false} onAddStep={() => { handleAddNewStepToBranch() }} />
            </div>
        </WorkflowStepContext.Provider >
    );
}