import React, { useContext, useEffect, useState } from 'react'
import { NodeItem } from './Components/nodeItem';
import { WorkflowStepContext } from '../../../../../contexts';
export const InputField = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {
    const [nodeItem, setNodeItem] = useState({ id: setupActionStep.property })
    const stepContext = useContext(WorkflowStepContext);

    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(setupActionStep.property, { value });
    }

    return (
        <div className='mt-4'>
            <h4 className="font-semibold">{setupActionStep?.name}{
                setupActionStep?.required? "*":""
            }:</h4>
            <NodeItem
                item={nodeItem}
                nodeValue={workflowStepValues[setupActionStep.property]?.value}
                handleNodeValueChange={handleNodeValueChange}
                previousSteps={stepContext.previousSteps}
                stepIdx={stepContext.stepIdx}
                workflowStep={workflowStep}
                previousStepsValues={stepContext.previousStepsValues} 
                elementHeight={setupActionStep?.elementHeight}
                />
            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>
    )
}
