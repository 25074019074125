import { ViewGridIcon } from '@heroicons/react/outline';
import React, { useContext } from 'react'
import { BranchContext, WorkflowContext } from '../../../../contexts';
import { specialApps, workFlowApps } from '../../../../domain/workflowApps';
import { appEventIconName, workflowStepType } from '../../../../utils/constants';
import WorkflowEventAppIcon, { } from '../workflowAppIcon';

export default function AppEventList({ onAppSelect, workflowStepId }) {
    const { workFlow: workflow } = useContext(WorkflowContext);
    const canCreateFilterStep = () => {
        const step = workflow.workflowSteps.find(x => x.id === workflowStepId);
        if (!step) {
            return false;
        }

        return step.stepType === workflowStepType.action
    }

    const canCreateBranchStep = () => {
        const step = workflow.workflowSteps.find(x => x.id === workflowStepId);
        if (!step) {
            return false;
        }

        const branchSteps = workflow.workflowSteps.filter(x => x.branchId === step.branchId);
        const stepIdx = branchSteps.findIndex(x => x.id === step.id);
        return branchSteps.length - 1 === stepIdx

            && step.stepType === workflowStepType.action
            // prevent nested branches
            && !step.branchId;
    }

    const canCreateDistributionStep = () => {

        return canCreateBranchStep();

    }

    const handleSelectApp = (appEvent) => {
        onAppSelect(appEvent);
    }

    return (
        <div className="min-h flex grid grid-cols-6 space-y-4 sm:space-x-4 sm:divide-x sm:divide-y-0 divide-y divide-solid overflow-y-auto">
            <div className="col-span-6 sm:col-span-4" >
                <div className="flex space-x-4 items-center">
                    <div className="flex-none h-12 w-12 p-1 border rounded-lg border-brand-pink text-brand-pink">
                        <ViewGridIcon />
                    </div>
                    <div>
                        <h3 className="text-gray-700 font-semibold">
                            App Event
                        </h3>
                        <div className="text-gray-400">
                            Start the workflow when something happens in a workflow app
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div>
                        <h3 className="text-gray-700 font-semibold">
                            Choose an app:
                        </h3>
                    </div>
                    <ul className="mt-4 ml-2 pb-2">
                        {
                            workFlowApps.map(ae => {
                                return (
                                    <li key={ae.name} className="p-2 w-3/4 flex space-x-2 items-center rounded-lg hover:bg-gray-50 hover:shadow-md cursor-pointer"
                                        onClick={e => handleSelectApp(ae)}>
                                        <WorkflowEventAppIcon iconKey={ae.appIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />

                                        <div className="text-gray-700 font-semibold">
                                            {ae.name}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className='col-span-6 sm:col-span-2 flex flex-col space-y-2 px-2 py-4 sm:py-0'>
                {
                    canCreateFilterStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.filterApp)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.filterIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Only continue if condition is met.
                            </div>
                        </div>
                    )
                }
                {
                    canCreateBranchStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.branchStep)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.branchIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Create new path if condition is met.
                            </div>
                        </div>
                    )
                }
                {
                    canCreateDistributionStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.distribution)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.distributionIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Split into multiple paths for every condition met
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
