import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from 'react';
import { getDialogDefinition as getDialogDefinitionElemets } from '../../../../api/dialogDefinition';
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from '../../../../contexts';
import { usePreviousSteps } from '../../../../hooks/usePreviousSteps';
import { workflowAppType } from '../../../../utils/constants';
import { getPlaceHolders } from '../../../../utils/slate';
import { stepValidator } from '../stepValidator';
import { convertValuesToArray } from '../utils';
import AppEventList from './appEventList';
import AppEventSelect from './appEventSelect';
import NextStepArrow from './nextStepArrow';
import SetupAction from './setupAction';
import WorkflowItemHeader from './workflowItemHeader';
import { toast } from 'react-hot-toast';

const WorkFlowStep = ({ workflowStep, stepCount, stepIdx = 0, activeStep, isDragOverlayComponent = false }) => {

    const [activeStepSection, setActiveStepSection] = useState(0);
    const { classNames } = useClassNames();
    const { workFlow: workflow, testValues, mapDialogElementsToWorkflowElement, ...workFlowContext } = useContext(WorkflowContext);
    const { workFlowValues } = useContext(WorkflowValuesContext);
    const { filterPreviousSteps } = usePreviousSteps(workflow.workflowSteps, workflow.workflowBranches);
    const [previousSteps, setPreviousSteps] = useState([]);
    const [previousStepsValues, setPreviousStepsValues] = useState([]);
    const [inUsePlaceholders, setInUsePlaceholders] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const handleAppSelect = (appEvent) => {
        workflowStep.name = appEvent.name;
        workflowStep.appEvent = appEvent;
        workFlowContext.updateWorkflowStep(workflowStep, workflowStep.id)
    }

    const handleAddNextStep = () => {
        workFlowContext.addStep(workflowStep.id, workflowStep.branchId);
    }

    const validateStep = (values) => {
        const errors = stepValidator(workflowStep, workFlowValues, values, workflow.workflowBranches, workflow?.workflowSteps || [], workFlowContext?.validationErrors || []);
        workFlowContext.setValidationError(workflowStep.id, errors);
        handleValidationErrorAfterDragAndDrop(errors);
    }

    const handleValidationErrorAfterDragAndDrop = (errors) => {
        const wfStepIndex = workFlowContext.dragAndDropChangedStepsRef.current.findIndex(ws => ws === workflowStep.id);
        if (wfStepIndex !== -1) {
            const currErrors = workFlowContext.validationErrors[workflowStep.id];
            if (JSON.stringify(currErrors) !== JSON.stringify(errors)) {
                if (Array.isArray(errors) && errors.find(e => e.error === "Placeholders missing ref")) {
                    toast.error(`Variables are no longer valid in the step '${stepIdx + 1}. ${workflowStep?.name}'.`);
                }
            }
            workFlowContext.dragAndDropChangedStepsRef.current.splice(wfStepIndex, 1);
        }
    }

    const loadStepValues = async (step) => {

        let valuePromises = [];
        if (step.appEvent?.type === workflowAppType.smartForm) {
            valuePromises = step.values.map((v, i) => {
                if (v.key == "smartformId") {
                    const valueSetupStep = step.selectedEvent.setupSteps?.find(x => x.property === "smartformId");
                    return new Promise(async (resolve, reject) => {
                        // console.log("SF", v.value.name, v.value.value);
                        const elements = await getDialogDefinitionElemets(v.value.value);
                        elements.push({ property: 'External Metadata' });
                        elements.push({ property: 'Datatable values' });
                        const converted = elements.map(e => {
                            return mapDialogElementsToWorkflowElement(e);
                        });
                        resolve(converted)
                    })
                }
                return Promise.resolve([v]);
            });
        }

        if (step.appEvent?.type === workflowAppType.restApi) {
            var apiResponseValues = step.values.find(x => x.key === "api-response")?.value;
            if (apiResponseValues) {
                valuePromises = Object.keys(apiResponseValues).map(k => {
                    return Promise.resolve({ key: k, value: { value: apiResponseValues[k], name: k } });
                });
            }
        }


        const values = (await Promise.all(valuePromises)).flat(1);
        return values.map(v => ({
            name: v.value.name,
            id: v.key,
            stepIdx: step.stepIdx,
            stepName: step.name,
            stepId: step.id,
            icon: step.appEvent.appIcon
        }));
    }

    const extractPlaceholdersFromObject = (obj, maxRecurseCount, currentIteration = 1) => {
        return Object.keys(obj).reduce((prev, curr,) => {
            const value = obj[curr];
            if (!value || currentIteration > maxRecurseCount) {
                return prev;
            }

            if (typeof value === 'string' || value instanceof String) {
                const placeHolders = getPlaceHolders(value);
                return [...prev, ...placeHolders];
            } else if (typeof value === 'object') {
                const placeHolders = extractPlaceholdersFromObject(value, maxRecurseCount, currentIteration + 1)
                return [...prev, ...placeHolders];
            }

            return prev;
        }, []);
    }

    const handleWorkflowChange = async () => {
        const steps = filterPreviousSteps(workflowStep.id).map(({ appEvent, id, name, selectedEvent }, i) => {
            return { appEvent, selectedEvent, id, name, stepIdx: i, values: convertValuesToArray(workFlowValues[id] || {}) }
        });
        setPreviousSteps(steps);
        const stepValues = workFlowValues[workflowStep.id] || {};
        const stepPlaceHolders = extractPlaceholdersFromObject(stepValues, 2);
        setInUsePlaceholders(prev => stepPlaceHolders.filter((x, i, self) => self.findIndex(s => s.id === x.id) === i));

        const promises = steps.map(step => loadStepValues(step));
        const prevStepsValues = await Promise.all(promises);
        setPreviousStepsValues(prevStepsValues);
        validateStep(prevStepsValues);

    }

    const isBranchOrDistributionStep = () => {
        return workflowStep?.appEvent?.type === workflowAppType.branch || workflowStep?.appEvent?.type === workflowAppType.distribution;
    }

    useEffect(() => {
        handleWorkflowChange();
    }, [workflow]);

    useEffect(() => {
        handleWorkflowChange();
    }, [workFlowValues]);

    return (
        <WorkflowStepContext.Provider value={{
            previousSteps: previousSteps,
            previousStepsValues: previousStepsValues,
            stepTestValues: testValues[workflowStep.id] || {},
            inUsePlaceholders,
            stepIdx: stepIdx
        }}>
            <div className="max-w-4xl">
                <div className={classNames("rounded-lg border shadow-lg divide-y divide-gray-200",
                    activeStep ? "" : "cursor-pointer opacity-70 hover:opacity-100"
                )}
                    onClick={e => {
                        if (!activeStep) {
                            workFlowContext.setActiveWorkflowStep(workflowStep.id);
                        }
                    }

                    }
                >
                    <WorkflowItemHeader workflowStep={workflowStep} stepIdx={stepIdx} activeStep={activeStep} />
                    <div className="bg-white divide-y divide-gray-200">
                        {
                            activeStep && (
                                workflowStep.appEvent ? (
                                    <>
                                        <AppEventSelect setupStepIdx={0} activeStepSection={activeStepSection} setActiveStep={setActiveStepSection} workflowStep={workflowStep} />
                                        <SetupAction setupStepIdx={1} activeStepSection={activeStepSection} setActiveStep={setActiveStepSection} workflowStep={workflowStep} workflowStepIdx={stepIdx} />
                                    </>
                                )
                                    : (
                                        //TODO: Add non appevent list
                                        <div className="py-4 px-6">
                                            <AppEventList onAppSelect={e => handleAppSelect(e)} workflowStepId={workflowStep.id} />
                                        </div>
                                    ))

                        }
                    </div>

                </div>
                {
                    (stepIdx < stepCount - 1 || (stepCount > 0 && workflowStep.appEvent)) && !isBranchOrDistributionStep() && !isDragOverlayComponent && (
                        <NextStepArrow isLastStep={stepCount - 1 === stepIdx} onAddStep={() => handleAddNextStep()} />
                    )
                }
            </div>
        </WorkflowStepContext.Provider>
    )
}

export default WorkFlowStep;