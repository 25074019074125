import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react'

export default function DataOptionsMenu({ optionsData, selectedValue, onSelect, isLoading }) {

    return (
        <div className="mt-2 relative">
            <Menu>
                <Menu.Button className="w-full">
                    <div className="flex justify-between items-center rounded-lg bg-gray-100 py-2 px-4 cursor-pointer hover:shadow-sm ">
                        <div className="font-semibold">
                            {selectedValue}
                        </div>
                        <div className="font-semibold text-gray-600">
                            <ChevronDownIcon className="h-5 w-5" />
                        </div>
                    </div>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="w-full overflow-y-auto max-h-64 z-10 absolute left-0 w-56 mt-1 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div>
                            {
                                isLoading && (
                                    <>
                                        <Menu.Item key={"load1"}>
                                            {({ active }) => (
                                                <button
                                                    className={`${active ? 'bg-gray-100' : 'text-gray-900'
                                                        } group flex rounded-md items-center w-full px-4 py-3 text-sm font-semibold`}
                                                >
                                                    <div className="h-4 w-1/2 rounded bg-gray-200 animate-pulse"></div>
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item key={"load2"}>
                                            {({ active }) => (
                                                <button
                                                    className={`${active ? 'bg-gray-100' : 'text-gray-900'
                                                        } group flex rounded-md items-center w-full px-4 py-3 text-sm font-semibold`}
                                                >
                                                    <div className="h-4 w-2/5 rounded bg-gray-200 animate-pulse"></div>
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </>
                                )
                            }
                            {
                                !isLoading && optionsData?.map(o => {
                                    return (
                                        <Menu.Item key={o.value}>
                                            {({ active }) => (
                                                <button
                                                    key={o.id}
                                                    className={`${active ? 'bg-gray-100' : 'text-gray-900'
                                                        } group flex rounded-md items-center w-full px-4 py-3 text-sm font-semibold`}
                                                    onClick={() => {
                                                        onSelect(o)
                                                    }}
                                                >
                                                    {o.name}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    )
                                })
                            }
                        </div>
                    </Menu.Items>

                </Transition>
            </Menu>
        </div>);
}
