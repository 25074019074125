import { appEventIconName, branchType, workflowAppType, workflowStepType } from "../utils/constants";

export const specialApps = {
    filterApp: {
        id: 'filter-app',
        type: workflowAppType.filter,
        name: "Filter",
        appIcon: appEventIconName.filterIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "filter-setup",
                name: "Filter Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "filterCondition",
                        name: "Name",
                        type: "filter-conditions",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    },
    branchStep: {
        id: 'branch-app',
        type: workflowAppType.branch,
        name: "Branch",
        appIcon: appEventIconName.branchIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "branch-setup",
                name: "Branch Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "branch",
                        name: "Name",
                        type: "branch-setup",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    },
    distribution: {
        id: 'distribution-app',
        type: workflowAppType.distribution,
        name: "Distribution",
        appIcon: appEventIconName.distributionIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "distribution-setup",
                name: "Distribution Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "distribution",
                        name: "Name",
                        type: "distribution-setup",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    }
};

export const workFlowApps = [
    {
        id: 'smart-form-app',
        type: workflowAppType.smartForm,
        name: "SmartForms",
        appIcon: appEventIconName.smartFormIcon,
        events: [
            {
                key: "submit",
                name: "Submit",
                stepType: workflowStepType.trigger,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "SmartForm",
                        type: "api-options",
                        dataType: "api",
                        dataSourceId: "active-smartforms"
                    }
                ]
            },
            {
                key: "value-change",
                name: "Element Value Change",
                stepType: workflowStepType.trigger,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "SmartForm",
                        type: "api-options",
                        dataType: "api",
                        dataSourceId: "active-smartforms"
                    }
                ]
            },
            {
                key: "new-smartform",
                name: "New",
                stepType: workflowStepType.action,
                forBranchTypes: [branchType.branch],
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "SmartForm",
                        type: "api-options",
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
            {
                key: "send-smartform",
                name: "Send New",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                setupSteps: [
                    {
                        property: "recipient-email",
                        name: "Recipient Email",
                        type: "input-field",
                        required: true
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "recipient-name",
                        name: "Recipient Name",
                        type: "input-field",
                        required: true
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "smartformId",
                        name: "SmartForm",
                        type: "api-options",
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
            {
                key: "set-values",
                name: "Update Values",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "SmartForm",
                        type: "api-options",
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
        ]
    },
    {
        id: 'rest-api-app',
        type: workflowAppType.restApi,
        name: "REST API",
        appIcon: appEventIconName.restApiIcon,
        events: [
            {
                key: "rest-api-post",
                name: "POST",
                stepType: workflowStepType.action,
                useTest: true,
                setupSteps: [
                    {
                        property: "url",
                        name: "Url",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "payload-type",
                        name: "Body - Format",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "dictionary", name: "Key - Values",
                            },
                            {
                                value: "json", name: "JSON",
                            }
                        ],
                        defaultValue: {
                            value: "dictionary", name: "Key - Values",
                        },
                        dataType: "",
                        dataSourceId: "",

                    },
                    {
                        property: "payload",
                        name: "Body",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "Allows for flattened json with '.' property separator",
                        displayCondition: {
                            property: "payload-type",
                            value: "dictionary"
                        },
                    },
                    {
                        property: "payload-json",
                        name: "Body",
                        type: "input-field",
                        elementHeight:52,
                        dataType: "",
                        dataSourceId: "",
                        helperText: "Raw JSON body",
                        displayCondition: {
                            property: "payload-type",
                            value: "json"
                        },
                    },
                    {
                        property: "auth-type",
                        name: "Authentication Type",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "none", name: "No Authentication",
                            },
                            {
                                value: "basic", name: "Basic Authentication",
                            },
                            {
                                value: "client-credentials", name: "OAuth 2.0 Client Credentials",
                            }
                        ],
                        defaultValue: {
                            value: "none", name: "No Authentication",
                        },
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "basic-auth",
                        name: "Basic Authentication",
                        displayCondition: {
                            property: "auth-type",
                            value: "basic"
                        },
                        type: "input-field",
                        helperText: "A pipe (|) separated username, password combo for standard HTTP authentication.",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-id",
                        name: "Client Id",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-secret",
                        name: "Client Secret",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "token-endpoint",
                        name: "Token Endpoint",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "scope",
                        name: "Scope",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "http-headers",
                        name: "Headers",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: ""
                    }
                ]
            },
            {
                key: "rest-api-get",
                name: "GET",
                stepType: workflowStepType.action,
                useTest: true,
                setupSteps: [
                    {
                        property: "url",
                        name: "Url",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "auth-type",
                        name: "Authentication Type",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "none", name: "No Authentication",
                            },
                            {
                                value: "basic", name: "Basic Authentication",
                            },
                            {
                                value: "client-credentials", name: "OAuth 2.0 Client Credentials",
                            }
                        ],
                        defaultValue: {
                            value: "none", name: "No Authentication",
                        },
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "basic-auth",
                        name: "Basic Authentication",
                        displayCondition: {
                            property: "auth-type",
                            value: "basic"
                        },
                        type: "input-field",
                        helperText: "A pipe (|) separated username, password combo for standard HTTP authentication.",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-id",
                        name: "Client Id",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-secret",
                        name: "Client Secret",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "token-endpoint",
                        name: "Token Endpoint",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "scope",
                        name: "Scope",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "http-headers",
                        name: "Headers",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: ""
                    }
                ]
            }
        ]
    },

    // {
    //     id: 'dummy-app',
    //     type: workflowAppType.workflow,
    //     name: "Workflow",
    //     appIcon: appEventIconName.workFlowIcon
    // }
];