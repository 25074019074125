export const convertValuesToArray = (obj) => {
    return Object.keys(obj).map(k => ({ key: k, value: obj[k] }));
}

export const placeHolderExists = (previousStepsValues, placeHolder) => {
    const flat = previousStepsValues?.flat(1) || [];
    const exists = flat.findIndex(x => x.id === placeHolder.id && x.stepId === placeHolder.stepId) !== -1;
    debugger;
    return exists;
}

export const convertStepTestValuesToList = (stepTestValues, workflowStepId) => {
    const placeholderIds = Object.keys(stepTestValues);
    const testValues = placeholderIds.reduce((agg, id, i) => {
        const value = stepTestValues[id];
        if (!value) {
            return agg;
        }
        return [{ workflowStepId, placeholderId: id, value: value }, ...agg];
    }, []);
    return testValues;
}