import { PlusIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useState } from 'react'
import { StepBranchConfig } from './Components/stepBranchConfig';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowContext } from '../../../../../contexts';
import { branchType } from '../../../../../utils/constants';

export const DistributionSetupActionStep = ({ setupActionStep, workflowStepValues, workflowStep }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { workFlow: workflow, ...workflowContext } = useContext(WorkflowContext);
    

    useEffect(() => {
        if (!workflowStep.workflowStepBranchIds || workflowStep.workflowStepBranchIds.length === 0) {
            const defaultDistributions = [{
                id: uuidv4(),
                name: "Distribution 1",
                stepId: workflowStep.id,
                triggerFilterGroups: [],
                parentBranchId: workflowStep.branchId,
                branchType: branchType.distribution
            },
            {
                id: uuidv4(),
                name: "Distribution 2",
                stepId: workflowStep.id,
                triggerFilterGroups: [],
                parentBranchId: workflowStep.branchId,
                branchType: branchType.distribution
            }];
            workflowContext.addBranches(defaultDistributions, workflowStep.id);
        }

        setIsLoaded(true);

    }, []);

    useEffect(() => {
        // console.log(workflow);
    }, [workflow]);

    const handleAddBranch = () => {
        workflowContext.addBranch({
            id: uuidv4(),
            name: "New Distribution",
            stepId: workflowStep.id,
            triggerFilterGroups: [],
            parentBranchId: workflowStep.branchId,
            branchType: branchType.distribution
        })
    }

    return <div className='py-4 space-y-2'>
        {
            workflow.workflowBranches && workflow.workflowBranches.filter(wb => wb.stepId === workflowStep.id).map((b, i) => {
                return (
                    <StepBranchConfig key={b.id} branch={b} />
                )
            })
        }
        <div className='py-4 flex'>
            <span className='p-2 rounded-lg bg-gray-200 cursor-pointer font-semibold flex-none flex items-center text-sm space-x-1'
                onClick={e => {
                    handleAddBranch();
                }}
            >
                <span className='h-4 w-4'><PlusIcon /></span>
                <span>Add Distribution</span>
            </span>
        </div>
    </div>
}
