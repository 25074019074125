import { ChevronDownIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useState } from "react";
import WorkflowEventAppIcon from "../workflowAppIcon";

export const StepValueOptions = ({ stepValues, step, onAdd, isCollapsedDefault }) => {
    const { classNames } = useClassNames();

    const [showAll, setShowAll] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(!isCollapsedDefault)
    
    return (
        <div>
            <div className="px-2 pt-2 flex justify-between items-center cursor-pointer" onClick={e => setIsCollapsed(prev => (!prev))}>
                <h3 className="font-semibold">
                    <div className="flex items-center space-x-2 cursor-pointer">
                        <WorkflowEventAppIcon iconKey={step.appEvent.appIcon} className="flex-none h-8 w-8 p-1 rounded shadow" />
                        <h3 className="font-semibold text-lg"> <span>{step.stepIdx + 1}. {step.name}</span></h3>
                    </div></h3>
                <div >
                    <ChevronDownIcon className="text-gray-600 h-5 w-5" />
                </div>
            </div>
            {
                !isCollapsed && (
                    <div className="my-4 ml-2 space-y-2">
                        {
                            (showAll ? stepValues : stepValues.slice(0, 5)).map(o => {
                                return (
                                    <div key={o.id} className="flex items-center space-x-2 cursor-pointer rounded-lg border-gray-200 border p-1 hover:shadow"
                                        onClick={e => {
                                            onAdd(o);
                                        }}
                                    >
                                        <WorkflowEventAppIcon iconKey={step.appEvent.appIcon} className="flex-none h-6 w-6 shadow rounded p-1" />
                                        <div className="font-semibold text-md"> <span>{step.stepIdx + 1}. {o.name}</span></div>
                                    </div>
                                )
                            })
                        }
                        {
                            !showAll && (
                                <div className="p-1">
                                    <div className="font-semibold hover:underline cursor-pointer"
                                        onClick={e => setShowAll(true)}>
                                        Show all...
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )

            }
        </div>
    )
}