import React, { Fragment, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import "./styles.scss";
import { OidcRoutesContext } from "../../contexts";
import { config } from "../../utils/config";
import { MenuBar } from '@metaforcelabs/metaforce-core';

export default function AdminLayout({ nav, subMenuNav, children }) {
  let { workflowId } = useParams();
  const oidcContext = useContext(OidcRoutesContext);

  const NavigationMenuReactLinkElement = (
    name,
    route,
    currentCheckValue = nav === route,
    featureEnabledCheckResult = true,
    children
  ) => {
    return {
      linkElement: <Link to={route}>{name}</Link>,
      name: name,
      route: route,
      current: currentCheckValue,
      featureEnabled: featureEnabledCheckResult,
      children
    };
  };

  const navigationMenu = [
    NavigationMenuReactLinkElement(
      'Home',
      '/',
      nav === 'admin/dashboard' || nav === 'dashboard' || nav === 'admin/workflow',
      true
    ),
    NavigationMenuReactLinkElement(
      'Workflow',
      nav === 'admin/workflow/editor' ? `/admin/workflow/editor/${workflowId}` : `/admin/workflow/data/${workflowId}`,
      subMenuNav === 'admin/workflow',
      nav === 'admin/workflow/data' || nav === 'admin/workflow/editor',
      [
        NavigationMenuReactLinkElement('Editor', `/admin/workflow/editor/${workflowId}`, nav === 'admin/workflow/editor'),
        NavigationMenuReactLinkElement('Data', `/admin/workflow/data/${workflowId}`, nav === 'admin/workflow/data'),
      ]
    ),
    NavigationMenuReactLinkElement(
      'List',
      '/admin/workflow-list',
      nav === 'admin/workflow_list',
      true,
    ),
  ];

  return (
    <>
      <div className={`fixed flex flex-col ${nav === 'admin/workflow/editor' ? "bg-#faf5e2" : "bg-gray-50"} h-screen w-screen overflow-auto`}>
        <MenuBar
          navigation={navigationMenu}
          homeButtonLink={config.centerpointUiBaseUrl}
          customerName={oidcContext?.customerInfo?.name}
          customerOrgNumber={oidcContext?.customerInfo?.orgNumber}
          userProfileImage={oidcContext?.userProfile?.userProfileImage}
          helpLink={config.docsUrl}
        />
        {children}
      </div>
    </>
  );
}