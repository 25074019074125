import { SearchIcon } from '@heroicons/react/outline'
import { Table, useAlertModal } from '@metaforcelabs/metaforce-core';
import { useTableSearch, useTableActions, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { activateWorkflowDefinition, deactivateWorkflowDefinition, destroyWorkflowDefinition, getWorkflowDefinitions } from '../../../api/workflowDefinition';
import { SpinnerBage } from '../../../components/Badge/spinnerBadge';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import NewWorkflow from '../newWorkflow';
import WorkflowDefinitionStateBadge from '../WorkFlowEditor/workflowDefinitionStateBadge';
import { WorkflowListCtxMenu } from './Components/workflowListCtxMenu';
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export default function WorkFlowList() {
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
    const tableActions = useTableActions(workflowDefinitions, 10, "createdDate", "desc");
    const [searchTerm, setSearchTerm] = useState();
    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const definitions = await getWorkflowDefinitions();
            setWorkflowDefinitions(definitions);
        }, "Failed to load workflows");
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
      if(workflowDefinitions.length === 0){
        return;
      }
      applySearchTerm(searchTerm);

    }, [workflowDefinitions])
    

    const handleWorkflowChanged = (workflowDef) => {
        setWorkflowDefinitions(prev => {
            const wfIdx = prev.findIndex(wf => wf.id === workflowDef.id);

            if (wfIdx !== -1) {
                prev[wfIdx] = workflowDef;
            }

            return [...prev];
        })
    }

    const handleWorkflowDestroyed = (workflowDef) => {
        setWorkflowDefinitions(prev => {
            const filtered = prev.filter(x => x.id !== workflowDef.id);
            return [...filtered];
        })
    }

    const handleSearch = ({ value: searchBy }) => {
        setSearchTerm(searchBy);
        applySearchTerm(searchBy);
    }

    const applySearchTerm = (searchBy) => {
        tableActions.search(searchBy, "name");
    }

    return (
        <ContentWrapper>
            <div className="flex flex-row justify-between items-end">
                <PageHeader
                    title="Workflows"
                    optionalSideElement={
                        <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                            <label htmlFor="desktop-search-candidate" className="sr-only">
                                Search
                            </label>
                            <div className="flex rounded-md shadow-sm">
                                <div className="relative flex-grow focus-within:z-10">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="desktop-search-candidate"
                                        id="desktop-search-candidate"
                                        className="hidden focus:ring-brand-pink focus:border-brand-pink w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                        placeholder="Search"
                                        onChange={e => handleSearch(e.target)}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            <div>
                <div className="mt-5 pb-5">
                    <button
                        type="button"
                        className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setShowNewDialog(true)}
                    >
                        Create new workflow
                    </button>
                </div>

                <div className="relative z-0">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() => tableActions.orderBy("name")}
                                            >
                                                Name {tableActions.getSortIcon("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableActions.orderBy("workflowDefinitionState")
                                                }
                                            >
                                                State {tableActions.getSortIcon("workflowDefinitionState")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableActions.orderBy("createdDate")
                                                }
                                            >
                                                Created{" "}
                                                {tableActions.getSortIcon("createdDate")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableActions.orderBy("updatedDate")
                                                }
                                            >
                                                Updated{" "}
                                                {tableActions.getSortIcon("updatedDate")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3"
                                            >
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {tableActions?.pageData?.map((element) => (
                                            <WorkflowTableRow key={element.id}
                                                workflowDefinition={element}
                                                onWorkflowChanged={wf => handleWorkflowChanged(wf)}
                                                onWorkflowDestroyed={wf => handleWorkflowDestroyed(wf)}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                                <Table.Pagination tableActions={tableActions} />
                            </div>
                        </div>
                    </div>
                </div>
                <NewWorkflow open={showNewDialog} setOpen={setShowNewDialog} />
            </div>
        </ContentWrapper>
    )
}

const WorkflowTableRow = ({ workflowDefinition, onWorkflowChanged, onWorkflowDestroyed }) => {
    const destroyAction = useToastAction();
    const activateAction = useToastAction();
    const { alertError, alertWarn } = useAlertModal();

    const handleDeactivateWorkflow = async (workflowDef) => {
        activateAction.execute(async () => {
            const updated = await deactivateWorkflowDefinition(workflowDef.id);
            onWorkflowChanged(updated)
        }, "Failed to disable")
    }

    const handleActivateWorkflow = async (workflowDef) => {
        const errors = workflowDef.errors.filter(e => e.type === "error");
        const warnings = workflowDef.errors.filter(e => e.type === "warning");
        if (errors.length > 0) {
            alertError("Workflow contains errors, please review workflow",
                () => { },
                false
            )
        } else if (warnings.length > 0) {
            alertWarn("Workflow contains warnings, still activate?", () => activateWorkflow(workflowDef), true, "Cancel", () => { }, "Activate");
        } else {
            activateWorkflow(workflowDef);
        }

    }
    const activateWorkflow = async (workflowDef) => {
        activateAction.execute(async () => {
            const updated = await activateWorkflowDefinition(workflowDef.id);
            onWorkflowChanged(updated);
        }, "Activate failed");
    }

    const handleDestroyWorkflow = async (workflowDef) => {
        destroyAction.execute(async () => {
            await destroyWorkflowDefinition(workflowDef.id);
            onWorkflowDestroyed(workflowDef);
        }, "Delete failed")
    }

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 relative" style={{ minWidth: "250px" }}>
                <NavLink className={"cursor-pointer hover:underline"}
                    to={`/admin/workflow/editor/${workflowDefinition.id}`}
                >
                    {workflowDefinition.name}
                </NavLink>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    (activateAction.isExecuting || destroyAction.isExecuting) ?
                        (<SpinnerBage />) :
                        (<WorkflowDefinitionStateBadge state={workflowDefinition.workflowDefinitionState} useShortText={true} />)
                }

            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowDefinition.createdDate
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowDefinition.updatedDate
                )}
            </td>
            <td className="p-2 whitespace-nowrap text-sm font-medium">
                <WorkflowListCtxMenu workflowDef={workflowDefinition}
                    onActivate={wf => handleActivateWorkflow(wf)}
                    onDeactivate={wf => handleDeactivateWorkflow(wf)}
                    onDestroy={wf => handleDestroyWorkflow(wf)}
                />
            </td>
        </tr>
    )
}
