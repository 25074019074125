import { DocumentAddIcon, DocumentReportIcon, PlayIcon, ViewListIcon } from '@heroicons/react/outline';
import * as Icon from "@heroicons/react/outline"
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import NewWorkflow from '../newWorkflow';
import WorkflowDefinitionStateBadge from '../WorkFlowEditor/workflowDefinitionStateBadge';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { getWorkflowDashboard } from '../../../api/workflowDashboard';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { ContentWrapper } from '@metaforcelabs/metaforce-core';

export default function Dashboard() {
    const dashboardAction = useToastAction();
    const [dashboardData, setDashboardData] = useState({});
    const [showNewWorkflow, setShowNewWorkflow] = useState(false)
    const shortcuts = [{
        icon: DocumentAddIcon,
        description: dashboardData?.highlightedWorkflows !== undefined && dashboardData?.highlightedWorkflows.length !== 0 ? 'Create workflow' : 'Create your first workflow',
        location: '#',
        color: 'text-indigo-500',
        onClick: () => setShowNewWorkflow(true)
    },
        // {
        //     icon: DocumentReportIcon,
        //     description: 'See and modify an existing workflow that we have created for you',
        //     location: '#',
        //     color: 'text-purple-500',
        //     onClick: () => console.log('testing')
        // }, {
        //     icon: PlayIcon,
        //     description: 'See a video on how to create and implement workflows for internal and external use',
        //     location: '#',
        //     color: 'text-yellow-500',
        //     onClick: () => console.log('testing')
        // }, {
        //     icon: ViewListIcon,
        //     description: 'See your list of workflows that you are working on or have published',
        //     location: '#',
        //     color: 'text-green-500',
        //     to: '/admin/list',
        // }
    ]

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        dashboardAction.execute(async () => {
            const results = await getWorkflowDashboard();
            setDashboardData(results);
        }, "Failed to load dashboard")
    }

    // const hasData = dashboardData?.topDialogDefinitions?.length > 0
    return dashboardAction.isExecuting ? (<></>) : (
        <ContentWrapper>
            <div className="mt-5">
                <h1 className="text-2xl font-bold">Welcome to WorkFlow</h1>
                <div className="mt-4 mb-4 text-gray-600">Some shortcuts to get you started</div>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 items-stretch">
                    {shortcuts.map((item, i) => (
                        <Link
                            key={`shortcut-${i}`}
                            to={item.to || '#'}
                        >
                            <div className="bg-purple-50 text-purple-700 shadow-sm overflow-hidden h-full border hover:bg-purple-100 cursor-pointer border-gray-200 sm:rounded-lg sm:p-4 flex items-center" onClick={item.onClick || null}>
                                <span className="inline-block">
                                    <item.icon className={`h-20 ${item.color} mr-3`} aria-hidden="true" />
                                </span>
                                <span className="inline-block">
                                    {item.description}
                                </span>
                            </div>
                        </Link>
                    ))}
                </dl>


                {/* <h3 className="text-lg leading-6 font-medium text-gray-900 mt-12">Last 30 days: Overall</h3> */}
                {/* <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                <div className="px-4 py-5 bg-white shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Workflows created</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{dashboardData?.dialogsCreated || 0}</dd>
                </div>
                <div className="px-4 py-5 bg-white shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Open rate</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{dashboardData?.openRate?.toFixed(2) || 0}%</dd>
                </div>
                <div className="px-4 py-5 bg-white shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Complete rate</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{dashboardData?.completedRate?.toFixed(2) || 0}%</dd>
                </div>
            </dl> */}
                <>
                    <div className='flex justify-between mt-12'>
                        <h3 className="text-xl leading-6 font-bold text-gray-900">Last 10 Workflows</h3>
                        <Link to={"/admin/workflow-list"}
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            See all <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>
                    <div className="mt-5 w-full flex flex-col">
                        <div className="-my-2  overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                {
                                    (!dashboardData?.highlightedWorkflows || dashboardData?.highlightedWorkflows?.length === 0) &&
                                    <div className="text-center border-2 rounded-md border-gray-300 border-dashed p-5 mt-4">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                vectorEffect="non-scaling-stroke"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                            />
                                        </svg>
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No workflows created</h3>
                                        <p className="mt-1 text-sm text-gray-500">Create a workflow from the list page</p>
                                        <div className="mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={e => setShowNewWorkflow(true)}
                                            >
                                                <Icon.PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                New workflow
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    !dashboardAction.isExecuting && dashboardData?.highlightedWorkflows && dashboardData?.highlightedWorkflows.length > 0 &&
                                    <div className="relative z-0">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Name
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                                >
                                                                    State
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Created{" "}
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Updated{" "}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {dashboardData?.highlightedWorkflows?.map((element) => (
                                                                <tr>
                                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                
                                                                                        </td> */}
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-indigo-600 relative" style={{ minWidth: "250px" }}>
                                                                        <NavLink className={"cursor-pointer hover:underline"}
                                                                            to={`/admin/workflow/editor/${element.id}`}
                                                                        >
                                                                            {element.name}
                                                                        </NavLink>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        <WorkflowDefinitionStateBadge state={element.workflowDefinitionState} useShortText={true} />
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {stringToLocaleDateTimeString(
                                                                            element.createdDate
                                                                        )}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {stringToLocaleDateTimeString(
                                                                            element.updatedDate
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
                <NewWorkflow open={showNewWorkflow} setOpen={setShowNewWorkflow} />
                {/* <NewDialog open={showNewDialog} setOpen={setShowNewDialog} /> */}
            </div>
        </ContentWrapper>
    )
}
