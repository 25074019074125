export const apiErrorHandler = (apiError) => {
  if (!apiError.response) { // if the api didn't respond, then the regular axios error response will not be set
    throw apiError
  }
  const { status, data } = apiError.response
  if (status === 400) {
    if (data.Message === 'Unable to save record. Record has been modified by another user.') {
      apiError.customErrorMessage = data.Message;
    }
    else if (data.ValidationErrors) {
      apiError.validationErrors = Object.keys(data.ValidationErrors).map((key) => {
        return {
          key,
          errors: data.ValidationErrors[key],
        }
      })      
    }
  }
  else if (status === 404) {
    window.location.replace("/not-found")
  }
  else if (status === 401 || status === 403) {
    window.location.replace("/unauthorized")
  }
  throw apiError
}