import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'

export default function CollabsableSection({ sectionTitle, children, stepIdx, isActiveStep, setActiveStep }) {

    return !isActiveStep ? (
        <div className="px-6 py-4 flex justify-between items-center cursor-pointer" onClick={e => setActiveStep(stepIdx)}>
            <h3 className="font-semibold">{sectionTitle}</h3>
            <div >
                <ChevronDownIcon className="text-gray-600 h-5 w-5" />
            </div>
        </div>
    ) : (
        <div className="py-4 px-6">
            <div className="flex justify-between items-center">
                <h3 className="font-semibold text-lg" >{sectionTitle}</h3>
            </div>
            {children}
        </div>
    );
}
