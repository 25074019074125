import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { deactivateWorkflowDefinition, getWorkflowDefinition, updateWorkflowDefinition } from '../../../api/workflowDefinition';
import { WorkflowContext, WorkflowValuesContext } from '../../../contexts';
import { workflowAppType, workflowStates, workflowStepType } from '../../../utils/constants';
import WorkFlowStep from './Components/workFlowStep';
import { useDebounceCallback } from '@react-hook/debounce';
import { v4 as uuidv4 } from 'uuid';
import { PencilIcon } from '@heroicons/react/outline';
import WorkflowDefinitionStateBadge from './workflowDefinitionStateBadge';
import FullScreenModal from '../../../components/Modals/fullScreenModal';
import { WorkflowBranchEditor } from './BranchEditor/workflowBranchEditor';
import { GenericModal, useClassNames, useInputFocusSelect, useToastAction } from '@metaforcelabs/metaforce-core';
import _ from "lodash";
import { ContentWrapper } from '@metaforcelabs/metaforce-core';
import { DndContextWrapper } from './DragAndDrop/dndContextWrapper';
import DndSortable from '../../../components/DragAndDrop/dndSortable';
import { useWorkflowStep } from '../../../hooks/useWorkflowStep';
import { updateStepIdxInPlaceholder } from "../../../utils/slate";

const convertToListValues = (workflowValues) => {
    const workflowSteps = Object.keys(workflowValues);

    const listed = workflowSteps.flatMap(k => {
        const stepValueKeys = Object.keys(workflowValues[k]);
        const listedValues = stepValueKeys.map(svk => ({ workflowStepId: k, property: svk, value: workflowValues[k][svk] }))
        return listedValues;
    })

    return listed;
}
const convertToListErrors = (validationErrors) => {
    const stepIds = Object.keys(validationErrors);
    const errors = stepIds.flatMap(workflowStepId => {
        const stepErrors = validationErrors[workflowStepId].map(err => ({ workflowStepId, type: err.type, error: err.error, errorField: err.errorField }))
        return stepErrors;
    });
    return errors;
}

// const convertToListTestValues = (testValues) => {
//     const stepIds = Object.keys(testValues);
//     const valuesList = stepIds.flatMap(workflowStepId => {
//         const stepValues = testValues[workflowStepId];
//         const placeholderIds = Object.keys(stepValues);
//         return placeholderIds.reduce((agg, id, i) => {
//             const value = stepValues[id];
//             if (!value) {
//                 return agg;
//             }
//             return [{ workflowStepId, placeholderId: id, value: value }, ...agg];
//         }, []);
//     });
//     return valuesList;
// }

export default function WorkFlowEditor() {
    const [nameInputRef, setNameInputRef] = useInputFocusSelect();

    const { workflowId } = useParams();
    const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
    const [activeWorkflowItem, setActiveWorkflowItem] = useState("");
    const [isEditingName, setIsEditingName] = useState(false);
    const [nameHovered, setNameHovered] = useState(false);
    const { classNames } = useClassNames();
    const [workflowDisabledAlertOpen, setWorkflowDisabledAlertOpen] = useState(false);
    const [branchEditModalOpen, setBranchEditModalOpen] = useState(false);
    const [editBranch, setEditBranch] = useState(null);
    const [workflow, setWorkFlow] = useState({}
        // {
        //     id: "asdasd",
        //     name: "WorkflowName",
        //     workflowSteps: [
        //         {
        //             id: 'asd',
        //             type: workflowStepType.trigger,
        //             name: ""
        //         }
        //     ]
        // }
    );
    const [workflowUpdatedDate, setWorkflowUpdatedDate] = useState();
    const [workflowValues, setWorkFlowValues] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [workflowTestValues, setWorkflowTestValues] = useState({});
    const [dragAndDropDisabled, setDragAndDropDisabled] = useState(false);
    const dragAndDropChangedStepsRef = useRef([]);
    const { canMove: canStepMove, getSteps, getCanMovedStepIds } = useWorkflowStep();
    const loadAction = useToastAction();
    const saveAction = useToastAction();

    const sortableItems = useMemo(() => getCanMovedStepIds(getSteps(workflow)) || [], [workflow]);

    const saveWorkflow = async () => {

        if (!workflow) {
            return;
        }

        saveAction.execute(async () => {
            workflow.values = convertToListValues(workflowValues);
            workflow.errors = convertToListErrors(validationErrors);
            workflow.updatedDate = workflowUpdatedDate || workflow.updatedDate;
            // workflow.testValues = convertToListTestValues(workflowTestValues);
            const { updatedDate } = await updateWorkflowDefinition(workflow);
            //console.log(udpated)
            // setWorkFlow(prev => updated);
            setWorkflowUpdatedDate(updatedDate);

        }, "Save failed")
    }

    const handleWorkflowNameChange = ({ value }) => {
        setWorkFlow(prev => {
            return { ...prev, name: value };
        })
        debounceSaveWorkflow();
    }

    const debounceSaveWorkflow = useDebounceCallback(saveWorkflow, 1000);

    const loadData = async () => {
        setIsInitiallyLoaded(false);
        if (!workflowId) {
            return;
        }
        loadAction.execute(async () => {
            let { values, ...workflowDef } = await getWorkflowDefinition(workflowId);
            const convertedValues = convertToKvpValues(values);

            if (workflowDef.workflowDefinitionState === workflowStates.active) {
                const { valuesUpdated, ...workflowDefUpdated } = await deactivateWorkflowDefinition(workflowDef.id)
                workflowDef = workflowDefUpdated;
                setWorkflowDisabledAlertOpen(true);

            }

            if (workflowDef.workflowSteps && workflowDef.workflowSteps.length === 1) {
                const firstStep = workflowDef.workflowSteps[0];
                setActiveWorkflowItem(firstStep.id);
            }
            setWorkFlowValues(convertedValues);
            setWorkFlow(workflowDef);
            setWorkflowUpdatedDate(workflowDef.updatedDate)
            setIsInitiallyLoaded(true);
        }, "Failed to load")
    }

    const handleStepChange = (step, workflowStepId) => {
        const idx = workflow.workflowSteps.findIndex(s => s.id == workflowStepId);
        setWorkFlow(prev => {
            prev.workflowSteps[idx] = step;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        })
        debounceSaveWorkflow();
    }
    const handleWorkflowStepNameChange = (workflowStepId, stepName) => {

        setWorkFlow(prev => {
            const i = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[i].name = stepName;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
        debounceSaveWorkflow();
    }

    const handleAddNewStep = (workflowStepId, branchId) => {
        const workflowStep = {
            id: uuidv4(),
            name: "",
            stepType: workflowStepType.action,
            branchId: branchId
        };
        const stepIdx = workflowStepId ?
            workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId) :
            workflow.workflowSteps.length;
        setWorkFlow(prev => {
            return { ...prev, workflowSteps: [...prev.workflowSteps.insert(stepIdx + 1, workflowStep)] };
        });

        setActiveWorkflowItem(workflowStep.id);

        debounceSaveWorkflow();
    }

    const handleCopyStep = (workflowStepId) => {
        const stepIdx = workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId);
        if (stepIdx === -1) return;

        const copiedWorkflowStep = _.cloneDeep(workflow.workflowSteps[stepIdx]);
        copiedWorkflowStep.id = uuidv4();
        copiedWorkflowStep.name = `(copy) ${copiedWorkflowStep.name}`;

        setWorkFlow(prev => {
            return { ...prev, workflowSteps: [...prev.workflowSteps.insert(stepIdx + 1, copiedWorkflowStep)] };
        });

        if (workflowValues[workflowStepId]) {
            const copiedValue = _.cloneDeep(workflowValues[workflowStepId]);

            setWorkFlowValues(prev => {
                return { ...prev, [copiedWorkflowStep.id]: copiedValue };
            });
        }

        setActiveWorkflowItem(copiedWorkflowStep.id);

        debounceSaveWorkflow();
    }

    const handleDeleteStep = (workflowStepId) => {
        if (validationErrors[workflowStepId]) {
            handleSetValidationError(workflowStepId, null);
        }

        const stepIdx = workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId);
        if (stepIdx === -1) return;

        const removedStep = workflow.workflowSteps[stepIdx];


        if (removedStep.appEvent?.type == workflowAppType.branch) {
            workflow.workflowBranches = workflow.workflowBranches.filter(x => !removedStep.workflowStepBranchIds.includes(x.id));
            workflow.workflowSteps = workflow.workflowSteps.filter(x => !removedStep.workflowStepBranchIds.includes(x.branchId));
        }

        const branchSteps = workflow.workflowSteps.filter(ws => ws.branchId == removedStep.branchId);
        const branchStepIdx = branchSteps.findIndex(ws => ws.id === workflowStepId);
        const prevStep = workflow.workflowSteps[branchStepIdx - 1];
        setWorkFlowValues(prev => {
            // console.log("after", prev)
            return { ...prev };
        });

        setWorkFlow(prev => {

            return {
                ...prev,
                workflowSteps: [...workflow.workflowSteps.filter(x => x.id !== workflowStepId)],
                workflowBranches: [...(workflow.workflowBranches || [])]
            };
        })

        setActiveWorkflowItem(prevStep?.id || "");
        debounceSaveWorkflow();
    }

    const setWorkflowStepAppEvent = (workflowStepId, appEvent) => {
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].appEvent = appEvent;
            prev.workflowSteps[stepIdx].selectedEvent = {};
            prev.workflowSteps[stepIdx].workflowStepBranchIds = [];
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
        setWorkFlowValues(prev => {
            return { ...prev, [workflowStepId]: {} };
        });
        debounceSaveWorkflow();
    }

    const handleSetStepEvent = (workflowStepId, event) => {
        console.log(event);
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].selectedEvent = event;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });

        setWorkFlowValues(prev => {
            return { ...prev, [workflowStepId]: {} };
        });

        debounceSaveWorkflow();
    }

    const handleSetStepTestResult = (workflowStepId, testResult) => {
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].testResult = testResult;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
    }

    const handleWorkflowStepValueChange = (workflowStepId, property, value) => {
        let hasChanged = false;
        const prevValue = workflowValues[workflowStepId]?.[property];
        if (prevValue && JSON.stringify(prevValue) !== JSON.stringify(value)) {
            hasChanged = true;
        }

        setWorkFlowValues(prev => {
            if (!prev[workflowStepId]) {
                prev[workflowStepId] = {};
            }

            return { ...prev, [workflowStepId]: { ...prev[workflowStepId], [property]: value } };
        });

        debounceSaveWorkflow();
        return hasChanged;
    }

    const handleSetFilterGroups = (workflowStepId, filterGroups) => {
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].filterGroups = filterGroups;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        })
        debounceSaveWorkflow();;
    }

    const handleAddBranch = (branch) => {
        const wfStep = workflow.workflowSteps.find(x => x.id === branch.stepId);
        const updateStepBranches = [...wfStep.workflowStepBranchIds, branch.id];
        const updated = [...workflow.workflowBranches || [], branch];
        setWorkFlow(prev => {
            prev.workflowBranches = updated;
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === wfStep.id);
            prev.workflowSteps[stepIdx].workflowStepBranchIds = updateStepBranches;
            return { ...prev };
        });
        debounceSaveWorkflow();
    }

    const handleAddBranches = (branches, workflowStepId) => {

        const branchIds = branches.map(x => x.id);
        const wfStep = workflow.workflowSteps.find(x => x.id === workflowStepId);
        console.log(wfStep)
        const updateStepBranches = [...wfStep.workflowStepBranchIds || [], ...branchIds];
        const updated = [...workflow.workflowBranches || [], ...branches];

        setWorkFlow(prev => {
            prev.workflowBranches = updated;
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].workflowStepBranchIds = updateStepBranches;
            return { ...prev };
        });
        debounceSaveWorkflow();
    }

    const handleUpdateBranch = (branch) => {
        if (!workflow.workflowBranches) {
            return;
        }

        const idx = workflow.workflowBranches.findIndex(x => x.id == branch.id);
        workflow.workflowBranches[idx] = branch;

        setWorkFlow(prev => {
            return { ...prev, workflowBranches: [...workflow.workflowBranches] };
        });
        debounceSaveWorkflow();
    }
    const handleRemoveBranch = (branch) => {
        if (!workflow.workflowBranches) {
            return;
        }

        const updated = [...workflow.workflowBranches.filter(b => b.id != branch.id)];
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === branch.stepId);

        workflow.workflowSteps[stepIdx].workflowStepBranchIds = workflow.workflowSteps[stepIdx].workflowStepBranchIds.filter(x => x != branch.id);
        setWorkFlow(prev => {
            return { ...prev, workflowBranches: updated, workflowSteps: [...workflow.workflowSteps.filter(x => x.branchId !== branch.id)] };
        });

        debounceSaveWorkflow();
    }

    const convertToKvpValues = (valuesList) => {
        const out = valuesList.reduce((acc, curr, i) => {
            acc[curr.workflowStepId] = { ...acc[curr.workflowStepId], [curr.property]: curr.value };
            return acc;
        }, {})
        return out;
    }

    const handleSetValidationError = (workflowStepId, error) => {
        const currError = validationErrors[workflowStepId];
        if (JSON.stringify(currError) === JSON.stringify(error)) {
            return;
        }

        setValidationErrors(prev => {
            if (error) {
                return { ...prev, [workflowStepId]: error };
            } else {
                delete prev[workflowStepId];
                return { ...prev };
            }
        })

        debounceSaveWorkflow();
    }

    const onBranchModalClose = () => {
        setBranchEditModalOpen(false);
        setEditBranch(null);
        setWorkFlowValues(prev => ({ ...prev }));
    }

    const handleUpdateStepTestValue = (workflowStepId, value) => {
        setWorkflowTestValues(prev => {
            if (value) {
                return { ...prev, [workflowStepId]: value }
            } else {
                delete prev[workflowStepId];
                return { ...prev };
            }
        });
        debounceSaveWorkflow();
    }

    const mapDialogElementsToWorkflowElement = (dialogElement) => {
        return { key: dialogElement.property, value: { value: dialogElement.property, name: dialogElement.property } };
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const activeWorkflowStepIndex = workflow.workflowSteps?.findIndex(ws => ws.id === active.id);
            const overWorkflowStepIndex = workflow.workflowSteps?.findIndex(ws => ws.id === over.id);
            if (activeWorkflowStepIndex !== -1 &&
                overWorkflowStepIndex !== -1 &&
                canStepMove(workflow.workflowSteps[overWorkflowStepIndex])) {
                workflow.workflowSteps.splice(overWorkflowStepIndex, 0, workflow.workflowSteps.splice(activeWorkflowStepIndex, 1)[0]);

                handleSetWorkflow(workflow);

                if (activeWorkflowStepIndex <= overWorkflowStepIndex) {
                    dragAndDropChangedStepsRef.current = workflow.workflowSteps.slice(activeWorkflowStepIndex, overWorkflowStepIndex + 1).map(ws => ws.id);
                } else {
                    dragAndDropChangedStepsRef.current = workflow.workflowSteps.slice(overWorkflowStepIndex, activeWorkflowStepIndex + 1).map(ws => ws.id);
                }

                debounceSaveWorkflow();
            }
        }
    }

    const updateStepIdxInValues = (steps) => {
        const clonedWorkflowValues = _.cloneDeep(workflowValues);
        for (const stepId in clonedWorkflowValues) {
            for (const property in clonedWorkflowValues[stepId]) {
                for (const key in clonedWorkflowValues[stepId][property]) {
                    if (typeof clonedWorkflowValues[stepId][property][key] === 'string' && clonedWorkflowValues[stepId][property][key].includes("stepIdx")) {
                        clonedWorkflowValues[stepId][property][key] = updateStepIdxInPlaceholder(clonedWorkflowValues[stepId][property][key], steps);
                    }
                }
            }
        }
        if (JSON.stringify(clonedWorkflowValues) !== JSON.stringify(workflowValues)) {
            setWorkFlowValues(clonedWorkflowValues);
        }
    }

    const updateStepIdxInTriggerFilterGroupsOfBranch = (branches, steps) => {
        branches.forEach(branch => {
            branch.triggerFilterGroups?.forEach(group => {
                group.groupFilters?.forEach(filter => {
                    if (filter.field?.stepIdx && filter.field?.stepId) {
                        const newStepIdx = steps?.findIndex(ws => ws.id === filter.field.stepId);
                        if (newStepIdx !== -1 && newStepIdx !== filter.field.stepIdx) {
                            filter.field.stepIdx = newStepIdx;
                        }
                    }
                })
            });
        })
    }

    const updateStepIdxInFilterSteps = (steps) => {
        steps.forEach(step => {
            step.filterGroups?.forEach(group => {
                group.groupFilters?.forEach(filter => {
                    if (filter.field?.stepIdx && filter.field?.stepId) {
                        const newStepIdx = steps?.findIndex(ws => ws.id === filter.field.stepId);
                        if (newStepIdx !== -1 && newStepIdx !== filter.field.stepIdx) {
                            filter.field.stepIdx = newStepIdx;
                        }
                    }
                })
            })
        })
    }

    const handleSetWorkflow = (value) => {
        const clonedWorkflow = _.cloneDeep(value);

        // update stepIdx in placeholders of values
        updateStepIdxInValues(clonedWorkflow.workflowSteps);

        // update stepIdx in triggerFilterGroup of branches
        updateStepIdxInTriggerFilterGroupsOfBranch(clonedWorkflow.workflowBranches, clonedWorkflow.workflowSteps);

        // update stepIdx in filter steps
        updateStepIdxInFilterSteps(clonedWorkflow.workflowSteps);

        setWorkFlow(clonedWorkflow);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        // console.log(workflow);
        // console.log(validationErrors);
    }, [workflow]);

    useEffect(() => {
        // console.log(workflowValues);
    }, [workflowValues]);

    useEffect(() => {
        // console.log(validationErrors);
    }, [validationErrors]);

    return workflow ? (
        <WorkflowContext.Provider value={{
            workFlow: workflow,
            validationErrors: validationErrors,
            activeStepId: activeWorkflowItem,
            testValues: workflowTestValues,
            saving: saveAction.isExecuting,
            mapDialogElementsToWorkflowElement: mapDialogElementsToWorkflowElement,
            deleteStep: (workflowStepId) => {
                handleDeleteStep(workflowStepId);
            },
            addStep: (workflowStepId, branchId) => {
                handleAddNewStep(workflowStepId, branchId);
            },
            copyStep: (workflowStepId) => {
                handleCopyStep(workflowStepId);
            },
            setStepAppEvent: (workflowStepId, appEvent) => {
                setWorkflowStepAppEvent(workflowStepId, appEvent);
            },
            setStepEvent: (workflowStepId, event) => {
                handleSetStepEvent(workflowStepId, event);
            },
            updateWorkflowStepName: (workflowStepId, stepName) => {
                handleWorkflowStepNameChange(workflowStepId, stepName)
            },
            setActiveWorkflowStep: (workflowStepId) => {
                setActiveWorkflowItem(workflowStepId);
            },
            setFilterGroups: (workflowStepId, filterGroups) => {
                handleSetFilterGroups(workflowStepId, filterGroups);
            },
            updateWorkflowStep: (step, workflowStepId) => {
                handleStepChange(step, workflowStepId);
            },
            addBranch: (branch) => {
                handleAddBranch(branch);
            },
            addBranches: (branches, workflowStepId) => {
                handleAddBranches(branches, workflowStepId);
            },
            updateBranch: (branch) => {
                handleUpdateBranch(branch);
            },
            removeBranch: (branch, stepId) => {
                handleRemoveBranch(branch);
            },
            setEditBranch: (branch) => {
                console.log(branch, workflow.workflowSteps);
                setEditBranch(branch);
                setBranchEditModalOpen(true);
            },
            setValidationError: (workflowStepId, error) => {
                handleSetValidationError(workflowStepId, error);
            },
            setStepTestResult: (workflowStepId, testResult) => {
                handleSetStepTestResult(workflowStepId, testResult);
            },
            updateStepTestValue: (workflowStepId, value) => {
                handleUpdateStepTestValue(workflowStepId, value);
            },
            dragAndDropDisabled: dragAndDropDisabled,
            setDragAndDropDisabled: setDragAndDropDisabled,
            dragAndDropChangedStepsRef: dragAndDropChangedStepsRef,
            handleDragEnd: (event) => {
                handleDragEnd(event);
            },
        }}>
            <WorkflowValuesContext.Provider value={{
                workFlowValues: workflowValues,
                updateWorkflowStepValue: (workflowStepId, property, value) => {
                    return handleWorkflowStepValueChange(workflowStepId, property, value);
                },
                getWorkflowStepValue: (workflowStepId, property) => {
                    return workflowValues[workflowStepId]?.[property];
                }
            }}>
                <ContentWrapper>
                    <div className="h-full flex flex-col">
                        <div className="flex-1 bg-gray-50 border border-gray-200 sm:rounded-lg">
                            <div className="max-w-4xl mx-auto h-full flex flex-col pt-16 pb-6">
                                <div className="pb-4 mb-4 border-b border-gray-200 max-w-4xl sm:flex sm:justify-between sm:flex-row-reverse">
                                    <div className="mb-2 sm:mb-0 text-right">
                                        <WorkflowDefinitionStateBadge state={workflow.workflowDefinitionState} />
                                    </div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 max-w-xl sm:w-4/6">
                                        <div className="flex rounded-md hover:shadow-sm">
                                            <input
                                                type="text"
                                                name="trigger-name"
                                                id="trigger-name"
                                                autoComplete="off"
                                                // ref={triggerNameInputRef}
                                                className={
                                                    classNames("text-lg flex-1 py-1 bg-transparent hover:bg-white focus:bg-white font-semibold focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md border-transparent hover:border-gray-300",
                                                        nameHovered ? "bg-white border-gray-300" : "")
                                                }
                                                value={workflow.name}
                                                ref={nameInputRef}
                                                onFocus={e => {
                                                    setIsEditingName(true);
                                                }}
                                                onMouseEnter={() => setNameHovered(true)}
                                                onMouseLeave={() => setNameHovered(false)}
                                                onChange={e =>
                                                    handleWorkflowNameChange(e.target)
                                                }
                                                onBlur={e => {
                                                    // workflowContext.updateWorkflowStepName(workflowStep.id, stepName);
                                                    // setIsRenaming(false)
                                                    setIsEditingName(false);
                                                    // setNameHovered(false);
                                                }}
                                            />
                                            {
                                                (isEditingName || nameHovered) && (<span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                                                    onMouseEnter={() => setNameHovered(true)}
                                                    onClick={e => {
                                                        setNameInputRef();
                                                    }}
                                                >
                                                    <PencilIcon className="h-5 w-5" />
                                                </span>)
                                            }
                                        </div>
                                    </h3>
                                </div>
                                <DndContextWrapper
                                    sortableItems={sortableItems}
                                >
                                    {
                                        getSteps(workflow).map((ws, i) => {
                                            return (
                                                <DndSortable
                                                    key={i.toString() + (ws?.name || "new")}
                                                    id={ws.id}
                                                    isSortable={canStepMove(ws)}
                                                    disabled={dragAndDropDisabled}
                                                >
                                                    <WorkFlowStep
                                                        workflowStep={ws}
                                                        stepCount={workflow.workflowSteps.filter(ws => !ws.branchId).length}
                                                        stepIdx={i}
                                                        activeStep={activeWorkflowItem === ws.id} />
                                                </DndSortable>
                                            )
                                        })
                                    }
                                </DndContextWrapper>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <GenericModal open={workflowDisabledAlertOpen} setOpen={setWorkflowDisabledAlertOpen} onConfirm={() => setWorkflowDisabledAlertOpen(false)} confirmButtonText={"Ok"} showCancelButton={false} title={"Info"}>
                    <div className='py-4'>
                        Workflow now disabled
                    </div>
                </GenericModal>
                <FullScreenModal
                    open={branchEditModalOpen} setOpen={setBranchEditModalOpen}
                >
                    <WorkflowBranchEditor branch={editBranch} onCloseModal={() => {
                        onBranchModalClose();
                    }} />
                </FullScreenModal>
            </WorkflowValuesContext.Provider>
        </WorkflowContext.Provider>
    ) : (<>Loading your Workflow...</>)
}