import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { getDialogDefinition } from "../../../../../api/dialogDefinition";
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from "../../../../../contexts";
import { NodeItem } from "./Components/nodeItem";
import '../components.scss'
import { convertValuesToArray } from "../../utils";
import { usePreviousSteps } from "../../../../../hooks/usePreviousSteps";

export const NodesSetupActionStep = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, stepIdx, workflowStep }) => {

    const [data, setData] = useState([]);
    const [nodeValues, setNodeValues] = useState({})
    const stepContext = useContext(WorkflowStepContext);

    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(setupActionStep.property, { ...workflowStepValues[setupActionStep.property], [nodeId]: value })
    }

    const loadData = async () => {
        switch (setupActionStep.dataSourceId) {
            case "dialog-definition":
                if (apiParameters.length === 1) {
                    const dialog = await getDialogDefinition(apiParameters[0].value);
                    const mapped = dialog.map(x => ({ ...x, id: x.property }))
                    setData(mapped)
                    return mapped;
                }
                break;
            default: break;
        }

    }

    useEffect(() => {
        // debugger;
        const propValues = workflowStepValues[setupActionStep.property];

        if (propValues) {
            setNodeValues(propValues);
        }

    }, [workflowStepValues]);

    useEffect(() => {
        loadData();
        // if(workflowStepValues[setupActionStep.property]) {
        //     onStepValueChange(setupActionStep.property, {})
        // }
    }, [apiParameters]);

    return (
        <div>{
            data.map(item => (
                <div key={item.id} className="mt-4">
                    <h4 className="">{item.property || '**missing-property-name**'}: </h4>
                    <NodeItem item={item} nodeValue={nodeValues[item.id]} handleNodeValueChange={handleNodeValueChange} previousSteps={stepContext.previousSteps} stepIdx={stepIdx} workflowStep={workflowStep} previousStepsValues={stepContext.previousStepsValues} />
                </div>
            ))}
        </div>
    )
}