import {
    Node
} from 'slate'

export const getParagrphPlaceHolders = (text) => {
    const lines = text.split("\n");
    return lines.map(getPlaceHolderMatches).flat(1);
}

const getPlaceHolderMatches = (textLine) => {
    const rgx = /\$\[(.+?)\]/g;
    return [...textLine.matchAll(rgx)]
}
const createParagraphFromTextLine = (line) => {
    const parts = [];

    let workingLine = line;

    while (true) {
        const matches = getPlaceHolderMatches(workingLine) || [];
        const match = matches[0];
        if (!match) {
            break;
        }
        const textBefore = workingLine.slice(0, match.index);
        parts.push({ text: textBefore });
        parts.push({
            type: 'workflow-value',
            data: JSON.parse(match[1]),
            children: [{ text: match[0] }],
        })
        const startIdx = textBefore.length + match[0].length
        workingLine = workingLine.slice(startIdx, workingLine.length)
    }
    parts.push({ text: workingLine });
    return {
        type: 'paragraph',
        children: parts
    }
}
export const deserialize = (plainText) => {
    const lines = plainText.split("\n");
    return lines.map(createParagraphFromTextLine);

}

export const serialize = (nodes) => {
    return nodes.map(n => Node.string(n)).join('\n')
};

export const wrapWorkflowValue = (value) => {
    return `$[${value}]`;
}
export const getPlaceHolders = (text) => {
    const matches = getPlaceHolderMatches(text);
    const parstedPlaceHolders = matches.map(x => JSON.parse(x[1]));
    return parstedPlaceHolders;
}

export const updateStepIdxInPlaceholder = (value, steps) => {
    if (!value) 
        return value;
    if (typeof value !== 'string' || !value.includes('stepIdx'))
        return value;

    let newValue = '';
    let workingValue = value;

    while (true) {
        const matches = getPlaceHolderMatches(workingValue) || [];
        const match = matches[0];
        if (!match) {
            break;
        }
        const textBefore = workingValue.slice(0, match.index);
        newValue += textBefore;

        const placeHolderObj = JSON.parse(match[1]);
        if (placeHolderObj.stepIdx && placeHolderObj.stepId) {
            const newStepIdx = steps?.findIndex(ws => ws.id === placeHolderObj.stepId);
            if (newStepIdx !== -1 && newStepIdx !== placeHolderObj.stepIdx) {
                placeHolderObj.stepIdx = newStepIdx;
            }
        }
        newValue += wrapWorkflowValue(JSON.stringify(placeHolderObj));

        const startIdx = textBefore.length + match[0].length
        workingValue = workingValue.slice(startIdx, workingValue.length)
    }
    newValue += workingValue;
    
    return newValue;
}