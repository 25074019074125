import { ChatAlt2Icon, ChipIcon, FilterIcon, ReplyIcon, ShareIcon } from '@heroicons/react/outline'
import { CenterPointIcon } from '@metaforcelabs/metaforce-core';
import React from 'react'
import { appEventIconName } from '../../../utils/constants';

const appEventIconConfigs = {
    [appEventIconName.smartFormIcon]: {
        icon: ChatAlt2Icon,
        iconBackGround: "bg-green-50",
        iconForeGround: "text-green-700",
    },
    [appEventIconName.workFlowIcon]: {
        icon: ShareIcon,
        iconBackGround: "bg-yellow-50",
        iconForeGround: "text-yellow-700 transform rotate-90",
    },
    [appEventIconName.filterIcon]: {
        icon: FilterIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    },
    [appEventIconName.branchIcon]: {
        icon: ReplyIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform -rotate-90",
    },
    [appEventIconName.restApiIcon]: {
        icon: ChipIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform -rotate-90",
    },
    [appEventIconName.distributionIcon]: {
        icon: ShareIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform",
    },
}

export default function WorkflowEventAppIcon({ iconKey, className, stateBadgeType = null, title = null }) {
    const appIcon = appEventIconConfigs[iconKey] || appEventIconConfigs[appEventIconName.smartFormIcon];
    return (
        <CenterPointIcon icon={appIcon.icon} iconBackGround={appIcon.iconBackGround} iconForeGround={appIcon.iconForeGround} className={className} title={title} stateBadgeType={stateBadgeType} />
    );
}
