
export const config = {
  apiBaseUrl: window._env_.REACT_APP_API_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL,
  workflowBaseUrl: window._env_.REACT_APP_WORKFLOW_BASE_URL,
  productLicense: '61de8cb5e123ded81bd7102e',
  workflowUsageFeatureCode: 'WFA',
  docsUrl: 'https://mfdoc.blob.core.windows.net/mfdocs/Workflow.html'
}

export const oidcConfig = {
    client_id: 'workflow_web',
    redirect_uri: window._env_.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    post_logout_redirect_uri: window._env_.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile email api.internal offline_access',
    authority: window._env_.REACT_APP_AUTHORITY,
    silent_redirect_uri: window._env_.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
  };