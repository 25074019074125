import { workflowStepType, workflowAppType } from '../utils/constants';

export const useWorkflowStep = () => {
    const canCopyOrMove = (workflowStep) => {
        if (workflowStep.stepType === workflowStepType.action &&
            workflowStep.appEvent?.type !== workflowAppType.branch &&
            workflowStep.appEvent?.type !== workflowAppType.distribution)
            return true;
        return false;
    }

    return {
        canCopy: (workflowStep) => {
            return canCopyOrMove(workflowStep);
        },
        canMove: (workflowStep) => {
            return canCopyOrMove(workflowStep);
        },
        getSteps: (workflow, branchId) => {
            let steps;
            if (branchId && typeof branchId === 'string') {
                steps = workflow.workflowSteps?.filter(ws => ws.branchId === branchId);
            } else {
                steps = workflow.workflowSteps?.filter(ws => !ws.branchId);
            }
            return steps || [];
        },
        getCanMovedStepIds: (steps) => {
            return steps?.filter(ws => canCopyOrMove(ws)).map(ws => ws.id) || [];
        },
        getStepIndexInEditor: (workflow, id) => {
            const stepIndex = workflow.workflowSteps?.findIndex(ws => ws.id === id);
            if (stepIndex === -1)
                return undefined;

            if (workflow.workflowSteps[stepIndex].branchId) {
                const branchSteps = workflow.workflowSteps.filter(ws => ws.branchId === workflow.workflowSteps[stepIndex].branchId);
                const indexInBranch = branchSteps.findIndex(ws => ws.id === id);
                return indexInBranch + 1;
            }

            return stepIndex;
        }
    }
}