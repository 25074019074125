import { workflowAppType } from "../../../utils/constants";
import { getParagrphPlaceHolders } from "../../../utils/slate";
import { placeHolderExists } from "./utils";

const validateSetupSteps = (eventSteps, workflowStepValues, previousStepsValues) => {
    const setupErrors = eventSteps.map(e => {
        // debugger;
        if (!workflowStepValues) {
            return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
        }

        const nodeValues = workflowStepValues[e.property];

        if (e.required && !nodeValues) {
            return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
        }

        if (e.type === "nodes") {
            if (!nodeValues) {
                return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
            }
            const nodesValid = Object.keys(nodeValues).every(k => {
                const nodeValue = nodeValues[k];
                if (nodeValue) {
                    const placeHolderMatches = getParagrphPlaceHolders(nodeValue);

                    return placeHolderMatches.every(match => {
                        const placeHolder = JSON.parse(match[1]);
                        return placeHolderExists(previousStepsValues, placeHolder);
                    });
                }
                return true;
            });
            if (!nodesValid) {
                return { type: "warning", error: "Placeholders missing ref", errorField: e.property }
            }
        }

        if (e.type === "input-field" && e.required && !nodeValues?.value) {
            return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
        }

        return null;
    }).filter(x => !!x);
    return setupErrors;
}

const validateStandardAppType = (workflowStep, workflowValues, previousStepsValues) => {
    let errors = [];
    if (!workflowStep.selectedEvent || !workflowStep.selectedEvent?.setupSteps) {
        errors.push({ type: "error", error: "No selected event", errorField: "selectedEvent" });
    }
    const setupStepErrors = validateSetupSteps(workflowStep?.selectedEvent?.setupSteps || [], workflowValues[workflowStep.id], previousStepsValues);
    if (workflowStep.selectedEvent?.useTest) {
        if (!workflowStep.testResult || !workflowStep.testResult?.isSuccess) {
            errors.push({ type: "error", error: "Test incomplete or failed", errorField: "testResult" });
        }
    }

    if (setupStepErrors.length) {
        errors = [...errors, ...setupStepErrors];
    }

    return errors;
}

const validateFilterGroups = (filterGroups, previousStepsValues) => {
    return filterGroups.length > 0 && filterGroups.every(x => {
        return x.groupFilters.some(gf => {
            const s = !!gf.condition && placeHolderExists(previousStepsValues, gf.field);
            debugger;
            return s;
        })
    })
}
const validateFilterAppType = (workflowStep, workflowValues, previousStepsValues) => {
    let errors = [];
    const groupsValid = validateFilterGroups(workflowStep.filterGroups || [], previousStepsValues);
    if (!groupsValid) {
        errors.push({ type: "error", error: "Condition incomplete", errorField: "groupFilters" })
    }

    return errors;
}

const validateBranchAppType = (workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors) => {
    debugger;
    let errors = [];
    var branches = workflowStep.workflowStepBranchIds?.map(wbid => workflowBranches.find(x => x.id === wbid));
    console.log(branches);
    const branchTriggersValid = branches && branches?.every(b => {
        return validateFilterGroups(b?.triggerFilterGroups || [], previousStepsValues);
    })

    if (!branchTriggersValid) {
        errors.push({ type: "error", error: "Branch triggers missing", errorField: "workflowStepBranchIds" })
    }

    const branchIds = branches.map(b => b.id);
    if (branchTriggersValid) {
        const branchSteps = allSteps.filter(x => branchIds.includes(x.branchId));
        const stepsWithError = branchSteps.map(x => stepErrors[x.id]).filter(error => !!error && error.length > 0);
        if (stepsWithError.length && stepsWithError.length > 0) {
            errors.push({ type: "error", error: "Branch Steps With Error", errorField: "workflowStepBranchIds" })
        }
    }
    console.log(errors)
    return errors;
}

export const stepValidator = (workflowStep, workflowValues, previousStepsValues, workflowBranches, allSteps, stepErrors) => {
    let errors = []
    
    if (!workflowStep.appEvent) {
        errors.push({ type: "error", error: "No App Event", errorField: "appEvent" });
    }

    if(workflowStep.appEvent?.type === workflowAppType.branch){
        debugger;
    }

    if (workflowStep.appEvent) {
        switch (workflowStep.appEvent.type) {
            case workflowAppType.filter: {
                errors = [...errors, ...validateFilterAppType(workflowStep, workflowValues, previousStepsValues)]
                break;
            }
            case workflowAppType.branch: {
                errors = [...errors, ...validateBranchAppType(workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors)]
                break;
            }
            case workflowAppType.distribution: {
                errors = [...errors, ...validateBranchAppType(workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors)]
                break;
            }
            default: {
                errors = [...errors, ...validateStandardAppType(workflowStep, workflowValues, previousStepsValues)]
                break;
            }
        }
    }

    return errors;
}